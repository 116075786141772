import React, { Suspense, lazy } from 'react';

//css
import './faqPage.css';

//components
import MainPageTemplate from '../pageTemplate/MainPageTemplate.jsx'
import LoadingPage from '../pageTemplate/LoadingPage.jsx';

const FaqPageFAQs = lazy(() => import('./FaqPageFAQs.jsx'));
const FaqPageCTA = lazy(() => import('./FaqPageCTA.jsx'));

export default function FaqPage() {
    return (
        <MainPageTemplate title={'FAQs | Marine AI'} showChatBot={false}>
            <Suspense fallback={<LoadingPage />}>
                <div className="faq-page-background">
                    <FaqPageFAQs />
                    <FaqPageCTA />
                </div>
            </Suspense>
        </MainPageTemplate>
    );
};