import { create } from 'zustand';
import BREAKPOINTS from '../breakpoints.js';

//global store
const useStore = create(set => ({
    //global device
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < BREAKPOINTS.mobile,
    isTable: window.innerWidth < BREAKPOINTS.tablet,

    updateSize: () => {
        set({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: window.innerWidth < BREAKPOINTS.mobile,
            isTable: window.innerWidth < BREAKPOINTS.tablet,
        });
    },
}));

export default useStore;
