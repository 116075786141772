import React, { useMemo } from 'react';
import shuffle from 'lodash.shuffle';

//components
import BlogPostPreview from './BlogPostPreview.jsx';

export default function BlogSidebar ({ otherPosts }) {
    //get random previews post from other posts
    const shuffledPosts = useMemo(() => shuffle(otherPosts).slice(0, 2), [otherPosts]);

    //blog-post page sidebar other post previews
    return (
        <div className='blog-post-page-sidebar-preview'>
            {shuffledPosts.map((post) => (
                <BlogPostPreview key={post.id} post={post} />
            ))} 
        </div>
    );
};