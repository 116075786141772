import React, { Suspense, lazy } from 'react';

//css
import './companyPage.css'

//components
import MainPageTemplate from '../pageTemplate/MainPageTemplate.jsx';
import LoadingPage from '../pageTemplate/LoadingPage.jsx';

const CompanyPageBlog = lazy(() => import('./CompanyPageBlog.jsx'));
const CompanyPageMissionStatement = lazy(() => import('./CompanyPageMissionStatement.jsx'));
const CompanyPageAbout = lazy(() => import('./CompanyPageAbout.jsx'));
const CompanyPageTeam = lazy(() => import('./CompanyPageTeam.jsx'));

export default function CompanyPage({ posts }) {
	return (
		<MainPageTemplate title={"About Us | Marine AI"} showChatBot={true}>
			<Suspense fallback={<LoadingPage />}>
				<CompanyPageMissionStatement />
				<CompanyPageAbout />
				<CompanyPageTeam />
				<CompanyPageBlog posts={posts} numSlides={3} />
			</Suspense>
		</MainPageTemplate>
	);
};
