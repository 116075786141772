import React from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

//Components
import MainPageTemplate from '../pageTemplate/MainPageTemplate.jsx';
import BlogSidebar from './BlogSidebar.jsx';

//hooks
import PageLink from '../../hooks/PageLink.jsx';

//css
import './blogPostPage.css'

export default function BlogPostPage({ BlogData }) {
	{/*get current post info from link*/ }
	const { postId } = useParams();
	const currentPost = BlogData.find(post => post.id === postId);
	const otherPosts = BlogData.filter(post => post.id !== postId);

	return (
		<MainPageTemplate title={`Marine AI Blog | ${currentPost.title}`} showChatBot={false}>
			<section id='blog' className='blog-post-page-container'>
				{/*Background Image*/}
				<div className='blog-post-page-background-image-container'>
					<img className='blog-post-page-background-image' src={require(`../../assets/blog-thumbnails/${currentPost.image}`)} alt={currentPost.title}></img>
				</div>

				{/*Page content*/}
				<div className='blog-post-page-content-container'>
					<div className='blog-post-page-blog-container'>
						<div className='blog-post-page-blog-padding'>
							{/*Left-hand Blog post title, author, and social links */}
							<div className='blog-post-page-title-author'>
								<h1 className="blog-post-page-font blog-post-page-title">{currentPost.title}</h1>
								<p className="blog-post-page-font blog-post-page-author-name">By {currentPost.author}</p>
								<div className='blog-post-page-social-icon-container'>
									<a href="https://www.linkedin.com/company/marine-ai/" className="blog-post-page-social-icons"><i className="fab fa-linkedin"></i>
										<p className='blog-post-page-font blog-post-page-text-icon'>LinkedIn</p>
									</a>
									<a href="https://www.facebook.com/MarineAi/" className="blog-post-page-social-icons"><i className="fab fa-facebook-f"></i>
										<p className='blog-post-page-font blog-post-page-text-icon'>Facebook</p>
									</a>
									<a href="https://www.twitter.com/Marine_AI1" className="blog-post-page-social-icons"><i className="fab fa-twitter"></i>
										<p className='blog-post-page-font blog-post-page-text-icon'>Twitter</p>
									</a>
								</div>
							</div>

							{/*blog post content and CTA*/}
							<div className='blog-post-page-text-cta'>
								<ReactMarkdown
									children={currentPost.intro}
									remarkPlugins={[gfm]}
									className="blog-post-markdown"
								/>
								<ReactMarkdown
									children={currentPost.content}
									remarkPlugins={[gfm]}
									className="blog-post-markdown"
								/>
								<p className="blog-post-page-text-outro">Want to know more? 
									<PageLink path='/contact' id='contact-us'> <span className="blog-post-page-text-link">Contact Us.</span></PageLink>	
								</p>
							</div>
						</div>
					</div>

					{/*blog page sidebar*/}
					<div className="blog-post-page-sidebar-container">
						<div className="blog-post-page-sidebar-padding">
							<BlogSidebar otherPosts={otherPosts} />
						</div>
					</div>
				</div>
			</section>
		</MainPageTemplate>
	);
};
