import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function PageLink({ children, path, id, extraClass }) {
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToSection = () => {
        const currentPath = location.pathname;
        const newPath = path;

        if (currentPath === newPath && id) {
            // Case: Link to section on the same page
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            } else {
                alert("The section you're looking for cannot be found.");
            }
        } else {
            // Case: Link to a different page or section
            navigate(newPath, { state: { scrollToId: id } });
        }
    };

    useEffect(() => {
        if (location.state?.scrollToId) {
            const section = document.getElementById(location.state.scrollToId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.state, location.pathname]);

    return (
        <span onClick={scrollToSection} className={`page-link ${extraClass}`} role='link'>
            {children}
        </span>
    );
};