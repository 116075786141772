import React from 'react';
import { useParams } from 'react-router-dom';

//components
import MainPageTemplate from '../pageTemplate/MainPageTemplate.jsx';

//hooks
import PageLink from '../../hooks/PageLink.jsx';

export default function JobPostPage({ JobData }) {
    const { jobId } = useParams();
    const selectedJob = JobData.find(job => job.id === jobId);

    if (!selectedJob) {
        return <div>Job not found.</div>;
    }

    return (
        <MainPageTemplate title={`Marine AI | ${selectedJob.title}`} showChatBot={false}>
            <section id='post' className='job-post-page-container'>
                <div className='job-post-page-content'>
                    <div className='job-post-page-post-box'>
                        <div className='job-post-page-text-container'>
                            {/*title*/}
                            <h1 className='job-post-page-job-title'>OPPORTUNITY: {selectedJob.title}</h1>

                            <p className='job-post-page-italic'>At Marine AI</p>

                            {/*back to career page*/}
                            <br />
                            <PageLink path={'/careers'} id={'job-openings'}>
                                <p className="job-post-page-back-link">❮ Back to Jobs</p>
                            </PageLink>
                            <br />

                            {/*about us*/}
                            <p className='job-post-page-subtitle'>About Us:</p>
                            <p>Join the forefront of maritime innovation with Marine AI, a leader in AI-driven solutions for the maritime industry, specialising in cutting-edge defence solutions. Our expertise lies in developing AI technologies that redefine the future of maritime security and efficiency. As we navigate uncharted waters, we're seeking a Senior Software Engineer to help steer our course towards new horizons.</p>

                            {/*job overview*/}
                            <p className='job-post-page-subtitle'>Overview:</p>
                            <p>{selectedJob.overview}</p>

                            {/*responsibilities*/}
                            <p className='job-post-page-subtitle'>Your Responsibilities:</p>
                            {selectedJob.responsibilities.map((responsibility, index) => (
                                <li key={index}>{responsibility}</li>
                            ))}

                            {/*qualifications*/}
                            <p className='job-post-page-subtitle'>Your Qualifications:</p>
                            {selectedJob.qualifications.map((qualifications, index) => (
                                <li key={index}>{qualifications}</li>
                            ))}

                            {/*our core values*/}
                            <p className='job-post-page-subtitle'>Our Core Values:</p>

                            <p>At Marine AI, our core values guide our journey:</p>

                            <li>Pioneering Innovation: We drive innovation in the maritime industry. Join Marine AI's forefront of cutting-edge AI solutions; safeguarding vessels, protecting crews, and shaping a sustainable future for our seas.</li>
                            <li>Thriving Together: At Marine AI, your unique voice matters. Create a maritime ecosystem where everyone thrives, navigating towards a safer and more diverse future.</li>
                            <li>Guarding Our Future: Your commitment to sustainability matters. Challenge the status quo with Marine AI. Together, we will reduce emissions and safeguard the maritime industry for a greener, brighter future.</li>

                            {/*job location*/}
                            <p className='job-post-page-subtitle'>Location:</p>
                            <p>UK - Remote</p>

                            {/*compensation*/}
                            <p className='job-post-page-subtitle'>Compensation:</p>
                            <p>{selectedJob.compensation}</p>

                            {/*end text*/}
                            <p>We proudly uphold equal opportunity employment and champion a diverse and inclusive workplace. Regardless of background, including but not limited to race, religion, colour, national origin, gender, sexual orientation, age, marital status, veteran status, or disability status, we welcome all qualified candidates to apply.</p>
                            <p>Are you ready to shape the future of maritime defence AI and make an impactful difference? Join us at Marine AI and be part of a passionate team dedicated to maritime excellence. Apply today to embark on this exciting journey!</p>

                            {/*apply now button*/}
                            <div className="job-post-page-apply-button-padding">
                                <button className="job-post-page-apply-button">
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </MainPageTemplate>
    )
};