import React from "react";

//media
import MAILogo from '../../assets/logos/marine_ai_logo.svg'

export default function LoadingPage() {
    return (
        <div className="loading-page">
            <img srcSet={MAILogo} className="loading-page-icon" />
{/* 
            <div className="loading-circle circle-1" />
            <div className="loading-circle circle-2" />
            <div className="loading-circle circle-3" /> */}

        </div>
    )
}