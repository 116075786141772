import React, { Suspense, lazy } from 'react';

//css
import './productPage.css'

//components
import MainPageTemplate from '../pageTemplate/MainPageTemplate.jsx';
import LoadingPage from '../pageTemplate/LoadingPage.jsx';

const ProductPageLanding = lazy(() => import('./ProductPageLanding.jsx'));
const ProductPageProducts = lazy(() => import('./ProductPageProducts.jsx'));
const ProductPagePartners = lazy(() => import('./ProductPagePartners.jsx'));

export default function ProductPage() {
	return (
		<MainPageTemplate title={'Products | Marine AI'} showChatBot={true}>
			<Suspense fallback={<LoadingPage />}>
				<ProductPageLanding />
				<ProductPageProducts />
				<ProductPagePartners />
			</Suspense>
		</MainPageTemplate>
	);
};