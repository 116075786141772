import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//hooks
import PageLink from '../../hooks/PageLink.jsx';

//media
import maiLogo from '../../assets/logos/marine_ai_logo.svg';

//docTree
import { docTree } from '../../data/doc-tree.js'

export default function NavBar() {
	const [mobileNavModalOpen, setMobileNavModalOpen] = useState(false);
	const { pathname } = useLocation();

	const [scrolled, setScrolled] = useState(pathname !== '/');

	//toggle open modal
	const openMobileModal = () => {
		setMobileNavModalOpen(!mobileNavModalOpen);
	}

	//disable scroll when nav modal is open
	useEffect(() => {
		if (mobileNavModalOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
			document.body.style.overflowX = 'hidden';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [mobileNavModalOpen]);

	//set 'scrolled' if the user scrolls from the top of the homepage
	const handleScroll = () => {
		if (pathname === '/') {
			const offset = window.scrollY;
			if (offset > 0) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		}
	};

	useEffect(() => {
		if (pathname === '/') {
			window.addEventListener('scroll', handleScroll);
		}

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	useEffect(() => {
		if (pathname !== '/') {
			setScrolled(true);
		}
	}, [pathname]);

	return (
		<div className={`nav-bar-container ${scrolled ? 'nav-bar-container-scrolled' : ''}`}>
			<div className="nav-bar-inner">
				<div className="nav-bar-left">
					{/*logo / homepage link*/}
					<PageLink path={'/'} id=''>
						<img className="nav-bar-logo" src={maiLogo} alt="Marine AI Logo"></img>
					</PageLink>
				</div>

				<nav className="nav-bar-right">
					<ul className='nav-bar-dropdown-container'>
						{docTree.map(page => ( //map page links from doc tree file
							<li key={page.title} className={scrolled ? 'nav-bar-dropdown-button-scrolled' : 'nav-bar-dropdown-button'}>
								{/*Page links*/}
								<PageLink path={page.link} id="" extraClass={scrolled ? 'nav-bar-page-link-scrolled' : 'nav-bar-page-link'}>
									{page.title}
								</PageLink>
								
								{page.sections && page.sections.length > 0 && (
									<div className={`dropdown-content ${scrolled && 'dropdown-content-scrolled'}`}>
										{page.sections.map(section => ( //section links
											<PageLink key={section.sectionTitle} path={page.link} id={section.id} extraClass={scrolled ? 'dropdown-link-scrolled' : 'dropdown-link'}>	
												{section.sectionTitle}
											</PageLink>
										))}
									</div>
								)}
							</li>
						))}
					</ul>

					{/*mobile nav modal button*/}
					{!mobileNavModalOpen ? (
						<div className='nav-bar-mobile-button' onClick={openMobileModal}>
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" aria-label="Open menu">
								<rect y="4" width="30" height="2" rx="0" fill="#02407a" />
								<rect y="14" width="30" height="2" rx="0" fill="#02407a" />
								<rect y="24" width="30" height="2" rx="0" fill="#02407a" />
							</svg>
						</div>
					) : (
						<div className='nav-bar-mobile-button' onClick={openMobileModal}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 16 16"
								aria-label="Close menu"
							>
								<path d="M4.354 4.354a.5.5 0 0 0-.708-.708L8 7.293 4.354 3.646a.5.5 0 1 0-.708.708L7.293 8l-3.647 3.646a.5.5 0 1 0 .708.708L8 8.707l3.646 3.647a.5.5 0 0 0 .708-.708L8.707 8l3.647-3.646a.5.5 0 0 0-.708-.708L8 7.293 4.354 3.646z" fill="#02407a" />
							</svg>
						</div>
					)}
				</nav>
			</div>

			{/*mobile nav modal overlay*/}
			{mobileNavModalOpen ? (
				<div className='nav-bar-mobile-modal'>
					<div className='nav-bar-mobile-modal-inner'>
						{docTree.map(page => (
							<div key={page.title} className='nav-bar-mobile-modal-link'>
								<PageLink path={page.link} id="">{page.title.toUpperCase()}</PageLink>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className='nav-bar-mobile-modal-hide' />
			)}
		</div>
	);
};
