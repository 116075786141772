import React, { Suspense, lazy } from "react";

// CSS
import "./homepage.css";
import useWindowWidth from "../../hooks/useWindowWidth.js";

// Page template
import MainPageTemplate from "../pageTemplate/MainPageTemplate.jsx";
import LoadingPage from "../pageTemplate/LoadingPage.jsx";

// Lazy-loaded components
const HomepageCarousel = lazy(() => import("./HomepageCarousel.jsx"));
const HomepageBlog = lazy(() => import("./HomepageBlog.jsx"));
const HomepageLanding = lazy(() => import("./HomepageLanding.jsx"));
const HomepageProductOverview = lazy(() =>
  import("./HompageProductOverview.jsx")
);
const HomepageContactCTA = lazy(() => import("./HompageContactCTA.jsx"));

export default function Homepage({ posts }) {
  const width = useWindowWidth();

  console.log(width);

  return (
    <MainPageTemplate title={"Marine AI"} showChatBot={true}>
      <Suspense fallback={<LoadingPage />}>
        <HomepageLanding />
        <HomepageCarousel />
        <HomepageProductOverview />
        <HomepageBlog posts={posts} numSlides={3} />
        <HomepageContactCTA />
      </Suspense>
    </MainPageTemplate>
  );
}
