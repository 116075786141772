import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//hooks
import PageLink from '../../hooks/PageLink.jsx';

//media
import maiLogoSmall from '../../assets/icons/Marine_AI_icon.svg';

//css
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function BlogPostPreview({ post }) {
	return (
		<div className='blog-post-preview-slider-padding'>

			{/*render a single blog-post preview box*/}
			<div className="blog-post-preview-box">

				{/*blog-post image*/}
				<div className="company-blog-image-container">
					<LazyLoadImage 
						srcSet={require(`../../assets/blog-thumbnails/${post.image}`)} 
						className="blog-post-preview-image" 
						alt={post.titleShort}
						effect='lazy'
					/>
				</div>

				{/*blog-post text content*/}
				<div className='blog-post-preview-content-wrap'>

					{/*inline author name and post date*/}
					<div className='blog-post-preview-author-date-wrap'>
						<img className="blog-post-preview-logo" src={maiLogoSmall} alt="Marine AI Logo"></img>

						<h2 className="blog-post-preview-font blog-post-preview-text-author-name">
							{post.author}
						</h2>

						<div style={{ width: '8px' }} />

						<svg
							xmlns="http://www.w3.org/2000/svg"
							id="Layer_1" data-name="Layer 1"
							viewBox="0 0 140.49 140.49"
							style={{ strokeLinecap: 'round', strokeLinejoin: 'round', stroke: '#262626', fill: 'none', width: '12px', strokeWidth: '13px' }}
						>
							<circle className="cls-2" cx="70.24" cy="70.24" r="63.74" />
							<polyline className="cls-1" points="70.46 34.31 70.46 69.93 102.11 87.6" />
						</svg>

						<time className="blog-post-preview-font blog-post-preview-date">
							{post.date}
						</time>
					</div>

					{/*title, text, and article link*/}
					<div className='blog-post-preview-text-button-wrap'>
						<div className='blog-post-preview-text-wrap'>

							{/*title*/}
							<h1 className="blog-post-preview-font blog-post-preview-title">
								{post.titleShort}
							</h1>

							{/*truncated text*/}
							<ReactMarkdown
								children={post.intro}
								remarkPlugins={[gfm]}
								className="blog-post-preview-text"
							/>
						</div>

						{/*article link*/}
						<PageLink path={`/blog-post/${post.id}`} id='' >
							<span className="blog-post-preview-button">Continue Reading</span>
						</PageLink>
					</div>
				</div>
			</div>
		</div>
	);
};
