import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';

//components
import ScrollToTop from './hooks/ScrollToTop.jsx';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<BrowserRouter>
		<ScrollToTop />
		<App />
	</BrowserRouter>
);