import React from 'react';
import { Helmet } from 'react-helmet';

//components
import NavBar from './NavBar.jsx';
import Footer from './Footer.jsx';
// import MAIChatbot from '../components/MAI-chatbot';

export default function MainPageTemplate({ title, children, showChatBot }) {
    return(
        <div>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <main>
                <NavBar />
                {/*{showChatBot && <MAIChatbot />}*/}
                {children} 
                <Footer />
            </main>
        </div>
    )
}