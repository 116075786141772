import React, { Suspense, lazy } from 'react';

//css
import './careerPage.css'

//components
import MainPageTemplate from '../pageTemplate/MainPageTemplate.jsx';
import LoadingPage from '../pageTemplate/LoadingPage.jsx';

const CareerPageValues = lazy(() => import('./CareerPageValues.jsx'));
const CareerPageJobOpenings = lazy(() => import('./CareerPageJobOpenings.jsx'));

export default function CareerPage({ JobPostData }) {
	return (
		<MainPageTemplate title={'Careers | Marine AI'} showChatBot={true}>
			<Suspense fallback={<LoadingPage />}>
				<CareerPageValues />
				<CareerPageJobOpenings JobPostData={JobPostData} />
			</Suspense>
		</MainPageTemplate>
	);
};
