const BlogData = [
	/*
	{
		id: 1,
		date: '2023-08-09',
		title: 'Revolutionizing the Maritime Industry: 4 ways Marine AI can increase safety and sustainability',
		titleShort: 'Revolutionizing the Maritime Industry',
		author: 'Marine AI',
		image: 's201-1.webp',
		intro: `
			In recent years, advancements in technology have sparked a revolution in the maritime industry. One prominent player at the forefront of this transformation is Marine AI, a pioneering company that specializes in data fusion, computer vision, and route planning. With a focus on collision prevention, portfolio risk mitigation, sustainability, and operational efficiency, Marine AI is reshaping the future of marine autonomy. In this blog post, we delve into the potential of Marine AI and how their innovative solutions are set to revolutionize the maritime sector.
			`,
		content: `
			<ol class="blog-post-page-text-subtitle">1.  Enhancing Collision Prevention with Data Fusion and Computer Vision</ol>
			<p class="blog-post-page-text">One of the critical challenges in maritime operations is collision prevention - collisions accounted for 13% of all casualties in 2021 (Allianz.com). Marine AI is tackling this issue head-on by leveraging advanced data fusion and computer vision technologies. By integrating data from various sources, such as radar, weather, AIS, and camera imagery, Marine AI's systems can provide real-time situational awareness to vessel operators, enabling them to make informed decisions and take proactive measures to prevent collisions. With computer vision algorithms analyzing the surrounding environment, potential risks and hazards can be identified, ensuring safer voyages for ships and minimizing the probability of accidents. </p>
			<ol class="blog-post-page-text-subtitle">2.  Portfolio Risk Mitigation through Route Planning </ol>
			<p class="blog-post-page-text">In an industry where uncertainty is inherent, managing portfolio risk is crucial for maritime companies. Marine AI's expertise in route planning empowers shipping operators to optimize their routes based on a myriad of factors, including weather conditions, sea traffic, and COLREGs. By considering these variables, Marine AI's algorithms can generate optimal routes that not only reduce the risk of accidents but also minimize fuel consumption, leading to substantial cost savings for shipping companies. This intelligent route planning ensures that vessels can navigate through the safest and most efficient paths, mitigating portfolio risks and enhancing operational performance. </p>
			<ol class="blog-post-page-text-subtitle">3.  Advancing Sustainability through Fuel Optimization and Reduced Crew Requirements</ol>
			<p class="blog-post-page-text">As the world increasingly focuses on sustainability, the maritime industry is under pressure to minimize its environmental impact. Marine AI recognizes this imperative and actively contributes to sustainable shipping practices. Through advanced data analytics, machine learning, and vessel health monitoring, Marine AI's solutions optimize fuel usage, identifying areas for efficiency improvement. By reducing excess fuel consumption, emissions are decreased, resulting in a greener maritime industry. Additionally, Marine AI's automation technologies enable streamlined operations, reducing the crew requirements aboard vessels. By optimizing crew size, shipping companies can enhance cost-effectiveness and further contribute to sustainability goals. </p>
			<ol class="blog-post-page-text-subtitle">4.  Shaping the Future of Marine Autonomy </ol>
			<p class="blog-post-page-text">The potential of Marine AI's technology extends beyond its immediate applications. By pushing the boundaries of marine autonomy, Marine AI is paving the way for the future of the industry. With ongoing research and development, they are exploring emerging technologies such as autonomous vessels and unmanned surface vehicles. By integrating their expertise in data fusion, computer vision, and route planning, Marine AI envisions a future where ships can operate autonomously, undertaking complex tasks while ensuring safety, efficiency, and sustainability. </p>
			<br />
			<p class="blog-post-page-text">Marine AI is revolutionizing the maritime industry with its cutting-edge technologies in data fusion, computer vision, and route planning. By focusing on collision prevention, portfolio risk mitigation, sustainability, and operational efficiency, Marine AI is driving the future of marine autonomy. Through their innovative solutions, shipping operators can benefit from enhanced safety, optimized routes, reduced fuel consumption, and improved cost-effectiveness. As the world embraces the potential of marine autonomy, Marine AI remains committed to shaping the future of the maritime industry and contributing to a more sustainable and efficient world at sea. </p>
			`,
	},
	{
		id: 2,
		date: '2023-08-10',
		title: `Safety at Sea: Mitigating Risks through Marine AI's Intelligent Collision Avoidance Systems`,
		titleShort: 'Safety at Sea: Mitigating Risks',
		author: 'Marine AI',
		image: 'NOMARS-render-2.webp',
		intro: `
			<p class="blog-post-page-text">Navigating the vast oceans has always been a challenging endeavor, with the safety of vessels and crew members being of paramount importance. In recent years, technological advancements have revolutionized the maritime industry, enabling the development of cutting-edge solutions aimed at enhancing safety and sustainability. Among these innovations, Marine AI, a UK-based company, has emerged as a trailblazer, utilizing artificial intelligence (AI) to create intelligent collision avoidance systems. By harnessing the power of computer vision, data fusion, and COLREG compliant route planning, Marine AI is transforming the way we navigate our seas, significantly reducing the risks associated with maritime operations.</p>
			<br />
			`,
		content: `
			<h2 class="blog-post-page-text-subtitle">Computer Vision: Enhancing Visibility Underwater and Beyond.</h2> 
			<p class="blog-post-page-text">One of the key components of Marine AI's collision avoidance systems is its advanced computer vision software. Designed to operate in challenging maritime environments, this software encompasses bespoke models for various critical tasks. Low observable marine object detection enables vessels to identify potential hazards that may be difficult to spot with the naked eye. Marine AI’s computer vision also incorporates blob detection under the Water Line capabilities, helping vessels detect and analyze objects beneath the water's surface, such as submerged obstacles or marine wildlife. Segmentation and waterline detection algorithms enable vessels to distinguish between different elements of the surrounding environment accurately. This precise identification allows for more efficient and reliable decision-making when it comes to navigation and collision avoidance.</p> 
			<br />
			<p class="blog-post-page-text">Addressing challenging lighting conditions is also a priority for Marine AI. By incorporating low-light enhancement algorithms, the system can improve visibility during nighttime or in adverse weather conditions. This capability significantly reduces the risks associated with reduced visibility, ensuring that vessels can operate safely even in challenging circumstances. </p> 
			<h2 class="blog-post-page-text-subtitle">Data Fusion: Integrated Information for Optimal Decision-making.</h2> 
			<p class="blog-post-page-text"> Marine AI's collision avoidance systems are not solely reliant on computer vision. Instead, they leverage data fusion techniques to combine information from multiple sources, enabling vessels to plan routes that are efficient, safe, and compliant with COLREG (International Regulations for Preventing Collisions at Sea) standards. By integrating data from RADAR, LIDAR, AIS, weather, and camera sources, the system creates a comprehensive situational awareness for the vessel and its crew. The integration of camera data complements the information gathered from other sources, adding a visual component to the decision-making process. This allows for the identification and tracking of objects, their classification (such as distinguishing between different types of vessels), and the prediction of their potential trajectories. </p> 
			<h2 class="blog-post-page-text-subtitle"> COLREG Compliance: Navigating Safely and Sustainably. </h2> 
			<p class="blog-post-page-text"> Marine AI's collision avoidance systems are designed to ensure COLREG compliance, an essential aspect of safe navigation at sea. COLREG establishes rules for the conduct of vessels in different situations, aiming to prevent collisions and maintain order on the waterways. By leveraging its advanced technology and data fusion capabilities, Marine AI's systems assist vessels in planning routes that adhere to COLREG standards. This ensures that vessels maintain the appropriate course, speed, and distance from other vessels, promoting safe and efficient navigation. </p> 
			<br />
			<p class="blog-post-page-text"> Additionally, the integration of weather data enables vessels to optimize their routes to minimize fuel consumption and reduce environmental impact. By planning the most efficient paths, vessels can reduce emissions, contributing to the sustainability of marine operations. </p> 
			<br />
			<p class="blog-post-page-text"> Marine AI's intelligent collision avoidance systems represent a significant leap forward in enhancing safety and sustainability in the maritime industry. By harnessing the power of computer vision, data fusion, and COLREG compliant route planning, these systems provide vessels with an unparalleled level of situational awareness and decision-making capabilities. As the maritime industry continues to embrace AI and advanced technologies, Marine AI's pioneering efforts in intelligent collision avoidance systems pave the way for safer and more sustainable operations at sea. By mitigating risks and promoting responsible navigation, Marine AI is shaping the future of maritime safety, ensuring that vessels and crew members can traverse the world's oceans with confidence. </p> 
			`,
	},
	{
		id: 3,
		date: '2023-08-01',
		title: 'Edge Computing – Why Marine AI is at the cutting edge of Marine Autonomy',
		titleShort: 'Why Marine AI is at the cutting edge',
		author: 'Marine AI',
		image: 'mai-blog-image-1.webp',
		intro: `
			<p class="blog-post-page-text"> In the realm of naval defense, technological advancements are continuously reshaping the landscape of maritime operations. With the ability to reduce the reliance on shore-based command centers, remote control, and internet connections, Marine AI is pushing the boundaries of what is possible in marine autonomy. In this blog post, we explore the concept of edge computing and delve into how Marine AI's innovative approach positions them at the forefront of marine autonomy. </p>
			<br />
			`,
		content: `
			<p class="blog-post-page-text"> In the midst of the Russian-Ukraine conflict, unmanned surface vessels (USVs) played a crucial role, providing Ukraine with an initial naval advantage in the Black Sea. These USVs, capable of autonomous operations, were utilized to effectively target Russian naval assets. However, recent developments have highlighted potential challenges to maintaining this advantage. SpaceX, the provider of Starlink satellite-internet communication technology, has restricted its licensing for direct military use on weapon systems since January 2023. The communication restrictions on the previous Ukrainian USVs have changed the balance of power, with Naval News reporting Russia now have the naval advantage in the black sea (<a href="https://www.navalnews.com/naval-news/2023/02/starlink-limits-ukraines-maritime-drones-at-time-of-new-russian-threat/" class="blog-post-page-text-link">Naval News</a>). </p> 
			<br />
			<p class="blog-post-page-text"> Here is where Marine AI's cutting-edge edge computing technology comes into play. By utilizing edge computing, Marine AI reduces the reliance on satellite internet communication technologies like Starlink. Unlike traditional methods that rely on shore-based command centers and continuous internet connectivity, edge computing enables autonomous systems to process and analyze data directly on board the vessels. This decentralized approach empowers Marine AI's autonomous systems to make intelligent and timely decisions without the need for real-time communication with a remote command center. Consequently, Marine AI's edge computing capability helps mitigate the communication restrictions posed by satellite internet technology limitations. </p> 
			<br />
			<p class="blog-post-page-text"> Edge computing is revolutionizing marine autonomy by providing Marine AI with a significant advantage in the ever-evolving field of maritime operations. By reducing the necessity for shore-based command centers, remote control, and internet connections, Marine AI is at the forefront of technological innovation. In an era where communication restrictions can impact the balance of naval power, Marine AI's edge computing capability ensures the resilience, efficiency, and security of their autonomous systems. With their commitment to pushing the boundaries of marine autonomy, Marine AI stands as a leader, pioneering the use of edge computing technology and redefining the future of maritime operations. </p> 
			`,
	},
	{
		id: 4,
		date: '2023-08-03',
		title: `Revolutionizing Maritime Defense: Marine AI's Autonomous Solutions and the Game-Changing Project CETUS`,
		titleShort: 'Revolutionizing Maritime Defense',
		author: 'Marine AI',
		image: 'CETUS-1.webp',
		intro: `
			<p class="blog-post-page-text"> In the ever-evolving world of maritime defense, technology plays a pivotal role in ensuring the safety and security of nations. Marine AI, a leading autonomous solutions provider, is at the forefront of revolutionizing defense operations at sea. Through their cutting-edge technologies, Marine AI is reshaping the defense landscape, bolstering risk mitigation, enhancing safety, and promoting sustainability. In this blog post, we explore Marine AI's transformative role and highlight the groundbreaking Project CETUS, a remarkable development in partnership with the Royal Navy. </p>
			<br />
			`,
		content: ` 
			<h2 class="blog-post-page-text-subtitle"> Mitigating Risks and Enhancing Safety with Autonomous Systems: </h2> 
			<p class="blog-post-page-text"> Marine AI's autonomous systems are specifically designed to mitigate risks and enhance safety in defense operations. By harnessing the power of computer vision and data fusion technologies, these solutions provide unmatched situational awareness, enabling defense clients to monitor and analyze the maritime environment in real-time. Marine AI's advanced computer vision algorithms detect potential threats and identify hazardous conditions, ensuring defense operations are executed with utmost caution and precision. By reducing human involvement in high-risk scenarios, Marine AI significantly minimizes the number of lives in harm's way, making defense missions safer and more efficient. </p> 
			<h2 class="blog-post-page-text-subtitle"> Project CETUS: Redefining Autonomous Submersibles: </h2> 
			<p class="blog-post-page-text"> One remarkable endeavor that showcases Marine AI's commitment to innovation and defense excellence is Project CETUS. Over the past decade, the Royal Navy has been exploring and operating autonomous underwater systems, primarily in minehunting operations, using small off-the-shelf technologies. However, Project CETUS takes autonomous submersibles to an entirely new level. Designed and built by Marine AI’s sister company MSubs, CETUS is an unparalleled crewless submersible specifically developed for the Royal Navy. With a length comparable to that of a bus, CETUS will be one of the largest and most complex autonomous submersibles operated by European navies. </p> 
			<br />
			<p class="blog-post-page-text"> Marine AI's autonomous solutions and the groundbreaking Project CETUS are transforming maritime defense. By combining cutting-edge technologies such as computer vision, data fusion, and edge computing, Marine AI revolutionizes risk mitigation, safety, and security for defense clients. The utilization of unmanned vessels, including the remarkable Project CETUS, reduces the number of lives in harm's way while ensuring efficient and cost-effective defense operations. With Marine AI leading the way, the seas are defended with innovation, dedication, and a vision for a safer and more sustainable future in maritime defense.</p>
			`,
	},
	*/
	{
		id: `a-visit-from-MAI-new-zealand`,
		date: '17 Oct 2023',
		title: `Exploring the Future of Marine Autonomy: A Visit from New Zealand`,
		titleShort: `A Visit from MAI New Zealand`,
		author: 'Marine AI',
		image: 'zane-mai-new-zealand.webp',
		intro: `*Over the past month, Marine AI had the privilege of hosting a special guest from our New Zealand office. Zane, a Masters student at the University of Waikato, has been exploring the compelling realm of Artificial Intelligence. His focus? Trustworthy AI image retrieval systems, a technology currently revolutionising medical imagining and agricultural management. But what prompts Zane to journey from New Zealand to the shores of the UK, and how does this tie into Marine AI's mission?*`,
		content: `
### Meet Marine AI New Zealand
Marine AI New Zealand is a dedicated team working diligently to implement our Guardian AI systems on vessels navigating the New Zealand coastline. Beyond that, they're harnessing the power of Guardian AI's proprietary Computer Vision systems to investigate the automation, maintenance, and optimisation of marine aquaculture. In essence, they're contributing to New Zealand's ambitious vision for the future of its aquaculture industry—a vision that seeks sustainability, productivity, resilience, and inclusivity. By 2025, New Zealand’s aquacultural economy is projected to exceed $1 billion, with even greater expectations of $3 billion by 2035.
### Zane's Journey with Marine AI
So, what brings Zane across the globe to our UK office? Zane joined Marine AI because he envisions a brighter, more sustainable future for the maritime sector. Having witnessed the transformative power of AI image retrieval systems in agriculture, he firmly believes that Marine AI will be at the forefront of a similar revolution in aquaculture.

But what about his Masters project? Zane's research delves into image retrieval systems, particularly those based on image embeddings for trustworthy AI. He's exploring applications in medical imaging and disease detection in agriculture, showcasing the incredible potential of AI in these domains. His time with Marine AI has allowed him to see his neural networks come to life in real-world applications.
### The Future of Marine Technology
As a student in the field of AI technology, Zane sees companies like Marine AI as pioneers shaping the future of marine technology. The work we do today, with Guardian AI and our pioneering computer vision models, paves the way for safer, more efficient, and environmentally conscious maritime operations.

Zane's visit to the UK office has been marked by exploration and learning. From the intricacies of our AI solutions to the kitted-out vessels, he's seen firsthand how we deliver on our promise to Guarding Your Future.
At Marine AI, we're not only dedicated to pushing the boundaries of marine autonomy but also to nurturing young talent like Zane. Together, we're steering towards a future where technology and sustainability coexist harmoniously, shaping the maritime sector into a more resilient and inclusive industry.	
			`,
	},
	{
		id: `USS-&-MAIs-integration-validated-at-Oi22`,
		date: `9 March 2022`,
		title: `Unmanned Survey Solutions (USS) & Marine AI’s integration validated at Oi22`,
		titleShort: `USS & MAI’s integration validated at Oi22`,
		author: 'Marine AI',
		image: 'uss-march-2022.webp',
		intro: `*Unmanned Survey Solutions (USS), based 
				in the South West of England, designs, 
				builds and operates the next-generation 
				Uncrewed Surface Vessels (USV’s). These 
				cutting edge USV’s have integrated Marine 
				AI’s ‘GUARDIAN Vision’ AI (Artificial 
				Intelligence) software to provide their 
				Uncrewed Surface Vessels (USVs) with 
				threat object detection and classification.*			
		`,
		content: `
Marine AI are delighted to be co-exhibiting with USS at Oceanology International 
(Oi22) exhibition and conference from 15th to 17th March at Excel, London. Oi22 will 
provide a perfect opportunity for 3 days of ‘on water’ demonstrations following their 
testing and development in December 2021. Marine AI and USS can utilise this 
fabulous opportunity to validate their significant developments integrated their based 
CV (computer vision) “plug-ins” with USS’s USV Accession.

GUARDIAN Vision is designed to provide a multi-platform, scalable, TLS-encrypted 
security that can be deployed on the edge for AI Vision applications and services. 
The plug-in’s are deployed in conjunction with the core CV capability providing realtime, distinct technical solutions for different CV sectors.


Matthew Ratsey, MD of Marine AI said “We are delighted to exhibit with James
Williams and his team at USS; it is a fabulous opportunity to demonstrate our 
successful collaboration with their USV showing our software to be truly vehicle 
agnostic and able to be successfully deployed and utilised for USV operations. We 
very much look forward to Oi22 and meeting visitors to discuss their USV operations 
both in scope and number.”

“Marine AI’s ‘GUARDIAN Vision’ package has provided a new and exciting features 
to our already state-of-the-art USVs, significantly enhancing our situational 
awareness and control. We very much look forward to demonstrating our combined 
capability during Oi22” said James Williams, Director of USS.

Visit us on Stand B201 or watch our daily Dockside Demos at Cabin 2 @11:00
		
			`,
	},
	{
		id: `water-witch-announces-marine-AI-partnership`,
		date: '18 Jan 2022',
		title: `Water Witch, pioneering manufacturer of marine litter collection workboats, announces partnership with Marine AI to develop next generation autonomous vessels.`,
		titleShort: `Water Witch Announces Marine AI Partnership`,
		author: 'Marine AI',
		image: 'water-witch.webp',
		intro: `*The exciting combination of Water Witch's proven Versi-Cat Trash Skimmer design and Marine AI!s
				GUARDIAN, will deliver a truly unique capability. The new vessels will be fully autonomous, smaller
				and lighter, and will be fitted with thrusters: these modifications will increase versatility, manoeuvrability and portability whilst reducing operating costs. The development represents a major advancement
				in the cost efficiency of retrieving plastic and the new boats will have sufficient recovery capacity for
				most applications.*
		`,
		content: `
GUARDIAN’s advanced data analytics will enable full automation of the process and will allow marine
litter and ocean plastic retrieval to be optimised. The detailed data captured and processed by
GUARDIAN will give better insights into the location and predicted movements of plastics in the marine environment, the variations and density of these hotspots, and the characteristics of the litter. The AI technology will use this monitoring, modelling and machine learning capability to increase
the recovery capacity, endurance and the cost-efficiency of the cleanup process.

The key features of the Water Witch’s next generation vessel are:

- Electric propulsion for zero emission and zero pollution operation
- GUARDIAN Vision will provide human-in-the-loop object detection and classification, with the ability to increase AI functionality up to a COLREG-compliant level control system by utilising the Guardian AUTONOMY software suite.
- Built from environmentally friendly, sustainable materials. Marine grade aluminium: 70% recycled content and completely recyclable at the end of its working life.
- High recovery capacity and efficient system for collecting all types of marine litter, algae and vegetation.
- Robust design which can be scaled up
- The technology can be refitted to existing Water Witch Trash Skimmers

Matthew Ratsey, MD of Marine AI, stated, "The increasing presence of marine litter, particularly plastics, in our oceans is one of the biggest challenges of our time. We are delighted to partner with Water Witch to assist them reduce the global marine litter problem.”

Jackie Caddick, Director of Liverpool Waterwitch Marine & Engineering Co. Ltd, explained that “Over
90% of marine debris reaches the ocean from rivers. By reducing the flow of plastics and waste in our
inland waterways, rivers, and estuaries, we can really make a huge difference. GUARDIAN will significantly enhance Water Witch’s capability: the new autonomous vessels will be able to collect, monitor
and model marine plastic occurrence, behaviour and flow paths. We are confident that the next generation Trash Skimmers will provide a proven and innovative solution to achieve cleaner waters.”`,
	},
	{
		id: `USS-adopts-guardian-ai`,
		date: '7 December 2021',
		title: ` Unmanned Survey Solutions (USS) adopts GUARDIAN Vision by Marine AI`,
		titleShort: `USS adopts Guardian AI`,
		author: 'Marine AI',
		image: 'uss-december-2021.webp',
		intro: `*Unmanned Survey Solutions (USS), a UK company that designs, builds and operates next-generation 
Unmanned Surface Vessels (USV’s), has adopted Marine AI’s ‘GUARDIAN Vision’ AI (Artificial Intelligence) software. These USV’s are versatile and provide a cost-effective solution for a range of hydrographic charting capabilities, and GUARDIAN Vision provides a COLREG-compliant control system for safety and path planning.*`,
		content: `
Marine AI was delighted to host USS at the Southwest's Marine Autonomous Hub in Turnchapel, Plymouth, for two days of on-water testing and development. The main focus was the testing of its 
game-changing suite of plug-ins for GUARDIAN Vision, a NVIDIA based CV (computer vision) system. The advantage of the new plug-ins is the increased speed to detect and identify objects but also 
making it more straightforward to customise solutions to meet customer requirements. 

GUARDIAN Vision offers multi-platform, scalable, TLS-encrypted security that can be deployed on the 
dge, for AI Vision applications and services. Plug-ins are used in conjunction with the core CV capability to provide real-time, distinct technical solutions for different CV sectors: 

- Waterline Detection and Correction
- Low Light Enhancement
- Digital Video Stabilisation
- Object Direction Estimation
- Object Detection and Labelling
- Object Classification
- Object Tracking
- LOMO (Low observable marine object) Detection

Matthew Ratsey, MD of Marine AI, announced, “we are delighted to be working with James Williams 
and his team at Unmanned Survey Solutions; it was great to see again how our agnostic software can 
be successfully deployed and utilised for USV operations. We very much look forward to assisting 
USS as their USV operations continue to increase in scope and number.”

“The GUARDIAN Vision package is impressive; the plug-ins analyse the feed from the vessel’s 
sensors and quickly relay the positional information on these hazards to the onboard control system 
and the remote pilot. GUARDIAN Vision significantly enhances our situational awareness and therefore the safety of the vessel, and vessels in the surrounding area”, said James Williams, Director of 
USS.`,
	},
	{
		id: `marine-ai-appoints-engaged-engineers-as-distributor`,
		date: '3 Nov 2021',
		title: `Marine AI Appoints Engaged Engineers as Distributor`,
		titleShort: `Marine AI Appoints Engaged Engineers`,
		author: 'Marine AI',
		image: 'engaged-engineers.webp',
		intro: `*Marine AI and Engaged Engineers have joined forces to introduce the most innovative
				Smart Shipping solutions for yachts, LNG carriers, ferries and bulk and container vessels. Engaged Engineers will offer Marine AI’s latest augmented intelligence products,
				based on the Mayflower Autonomous Ship’s system, to its maritime clients.*`,
		content: `
The Guardian Vision, Manned, Unmanned and Port solutions will reduce the risk of
collisions, minimise vessel damage and down-time, reduce operational costs and improve fuel efficiency and the safety of crew

The Guardian Manned solution uses the onboard AI Captain’s “foresight” to assist
crew members with decision-making at sea. The system provides an enhanced assessment of navigation risk which enablesthe best possible lowest-risk actions to be taken.

Erik Pouwen and Michael Lok, CEO and COO of Engaged Engineers, announced, “We
are excited to be working with Marine AI to help transform how we sail the oceans:
making voyages more secure, efficient and sustainable.” Matthew Ratsey, CEO of Marine AI, explained, “There is huge interest in our products, and close cooperation with
Engaged Engineers will expand our reach and allow us to install and deploy more turnkey smart shipping solutions.”

The Mayflower Autonomous Ship (MAS) project is the result of years of work and a
global collaboration between: marine research non-profit ProMare, IBM, Nvidia, and
dozens of partners from across industry and academia. The partners have worked together to design, build and sail the world'sfirst full-sized, fully autonomous unmanned
ship across the Atlantic Ocean. The Guardian Vision, Manned, Unmanned and Port solutions are the first products that are ready to be introduced to the market

The augmented intelligence software and the smart sensors enable a ship to react to
often-treacherous ocean environments. The systems comply with maritime law
(COLREG & SOLAS) and empower crews to make crucial split-second decisions. Additionally, they will re-route vessels around harsh weather environments and collect and
analyse a significant volume of ocean data. This all happens 24/7.
			`,
	},
	{
		id: `XOCEAN-adopts-guardian-ai-vision`,
		date: '16 March 2021',
		title: `XOCEAN adopts GUARDIAN Vision by Marine AI`,
		titleShort: 'XOCEAN adopts GUARDIAN AI Vision',
		author: 'Marine AI',
		image: 'XOCEAN.webp',
		intro: `*XOCEAN, a leading international USV operator has recently adopted Marine AI’s ‘GUARDIAN Vision’
			Artificial Intelligence software to enhance their Uncrewed Surface Vessels (USVs) situational
			awareness. Using USVs, XOCEAN provides a safe, economic and carbon neutral solution to ocean
			data collection to multiple industries such as Offshore Wind.*`,
		content: `
The 'GUARDIAN Vision’ package, provides "bandwidth-friendly’ actionable information.

‘GUARDIAN Vision’ enhances the USVs’ land-based teams’ situational awareness by analysing the
on-board camera feeds to detect and classify hazards and provide positional information on these
hazards to the on-board control system and the remote pilot. This allows data transfer volumes to be
reduced and delivers significant data and operational cost savings.

“Marine AI’s ‘GUARDIAN Vision’ Artificial Intelligence software is used to enhance our USVs
situational awareness” said Andrew Carlisle, Chief Technical Officer, XOCEAN. “We are impressed
with the product performance and customer service we have experienced since working with Marine AI”.

“Our ‘GUARDIAN Vision’ package is the first stage of Marine AI’s modular system. Our software
provides scalability to meet higher levels of autonomy and control. This ‘GUARDIAN Autonomy’
package, installed on the Mayflower Autonomous Vessel (mas400.com), provides our uncrewed
vessel with a COLREG-compliant fully autonomous capability,” said Matthew Rattsey, MD of Marine AI.
		`,
	},
	{
		id: `MARTAC-&-msubs-announce-alliance`,
		date: '24 Sept 2020',
		title: `Maritime Tactical Systems, Inc. and MSUBS Limited Announce Unmanned Alliance`,
		titleShort: 'MARTAC & MSubs announce Alliance',
		author: 'Marine AI',
		image: 'MARTAC.webp',
		intro: `*Maritime Tactical Systems, Inc. (“MARTAC”) and MSUBS Limited (“MSUBS”) are pleased to announce they have entered into an
			unmanned alliance combining MARTAC’s industry leading innovative maritime unmanned surface vehicle (USV) MANTAS with MSUBS’s design, manufacture and operation of manned and
			unmanned underwater vehicle (UUV) ‘submersibles’ and artificial intelligence (AI) for military
			and commercial markets.</p>*`,
		content: `
The MARTAC-MSUBS alliance brings together deep skills in high performance USV and UUV 
technologies to support customers’ successful unmanned missions. MARTAC and MSUBS will be
exploring how they can leverage their respective unmanned systems to enhance autonomous 
maritime capabilities for the global military and commercial markets. MARTAC will also be working with Marine AI, a subsidiary of MSUBS, to integrate their Guardian AI artificial intelligence 
capabilities into MANTAS’ TASKER Command & Control (C2) system to provide advanced multirole COPS’ capabilities across multiple domains. </p>

MSUBS will also support MARTAC on global sales of the MANTAS System with focus on the 
United Kingdom and European Union.

The MARTAC-MSUBS alliance is poised to address emerging market needs. MSUBS’ understanding of Unmanned and Manned Underwater systems and AI architecture is complimentary 
to MARTAC’s innovative best-in-class high performance MANTAS systems.” said Bruce Hanson,
MARTAC’s Chief Executive Officer. “With a broad combined Unmanned product line and complimentary technologies, this alliance will allow us to drive far greater adoption for our customers.” </p>

“Entering into an alliance with MARTAC allows us to deliver comprehensive unmanned maritime 
solutions for our clients. This covers the full maritime spectrum of surface and subsurface mission profiles.” said Brett Phaneuf, MSUBS, Managing Director.  </p>

**About MARTAC**

[MARTAC](https://martacsystems.com/) is an innovator in the development and manufacture of Unmanned Maritme Systems. 
MARTAC was founded in 2012 and offers a broad product line of unmanned systems called 
MANTAS to the military, commercial and scientific markets. MANTAS systems are fully/semiautonomous and full operator-controlled vessels that are scalable from 2 to 20 meters designed 
to operate Beyond Human Capability. 

**About MSubs**

[MSubs](https://www.msubs.com/) is an independent company specialising in the provision of Manned and Unmanned Submarines / Vehicles. MSUBS has been designing, manufacturing and operating since 1986 for defence, research and commercial survey. MSUBS have just launched the Mayflower Autonomous 
Ship (MAS), with ProMare, their non-profit marine research and exploration charity, and in 
partnerships with IBM. The MAS is an unmanned ship which will set sail from Plymouth and attempt to complete a pioneering crossing of the Atlantic Ocean. 
		`,
	},
];

export default BlogData;
