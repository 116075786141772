import React from 'react';

//hooks
import PageLink from '../../hooks/PageLink.jsx';

import { docTree } from '../../data/doc-tree.js';

export default function Footer() {
	const year = new Date().getFullYear();

	return (
		<footer className='footer-container'>
			<div className="footer-padding">
				<div className="footer-top">

					{/*title address email*/}
					<div className="footer-top-left">
						<h1 className="footer-h1">Marine AI</h1>
						<br />
						<a href="mailto:info@marineai.co.uk" className="footer-text-link">info@marineai.co.uk</a>
						
						<p className="footer-text">Building 1</p>
						<p className="footer-text">Turnchapel Wharf</p>
						<p className="footer-text">Barton Road</p>
						<p className="footer-text">Plymouth</p>
						<p className="footer-text">PL99RQ</p>
					</div>

					{/*page links*/}
					<div className="footer-top-right">
						{docTree.map(page => { //map over doc tree
							//only render links with sections
							if (page.sections.length > 0) {
								return (
									<div key={page.title} className="footer-top-right-quarter">
										{/*Page Links*/}
										<PageLink path={page.link} id="">
											<h2 className="footer-h3-link">{page.title}</h2>
										</PageLink>

										<br />

										{/*Section Links*/}
										{page.sections.map(section => (
											<PageLink key={section.id} path={page.link} id={section.id}>
												<span className="footer-text-link">{section.sectionTitle}</span>
											</PageLink>
										))}
									</div>
								);
							}
							return null;
						})}
					</div>
				</div>

				{/*social media links*/}
				<hr className="footer-divider"></hr>
				<div className="footer-bottom">
					<p className="footer-bottom-text">
						<a href="https://www.facebook.com/MarineAi/" aria-label='Marine AI Facebook' className="social-icon"><i className="fab fa-facebook-f"></i></a>
						<a href="https://www.twitter.com/Marine_AI1" aria-label='Marine AI Twitter' className="social-icon"><i className="fab fa-twitter"></i></a>
						<a href="https://www.linkedin.com/company/marine-ai/" aria-label='Marine AI LinkedIn' className="social-icon"><i className="fab fa-linkedin"></i></a>
					</p>
					<p className="footer-bottom-text">&copy; {year} Marine AI ltd. All rights reserved.</p>
				</div>
			</div>
		</footer>

	);
};
