const JobPostData = [
    // {
    //     id: `senior-software-engineer`,
    //     title: `Senior Software Engineer`,
    //     overview: `As a Senior Software Engineer at Marine AI, you will be a vital force in our engineering team, driving the development of AI-powered solutions that safeguard maritime defence. Your expertise in statically typed languages and experience with containerized systems will contribute to our mission of delivering unparalleled edge-based AI capabilities, including on systems like the AGX.`,
    //     responsibilities: [
    //         `Forge innovative pathways to enhance software quality, employing your expertise in statically typed languages and containerized deployment to embedded edge systems.`,
    //         `Act as a guiding light for the engineering department, sharing your knowledge and coaching cross-disciplinary teams.`,
    //         `Chart the technical voyage by creating a long-term roadmap aligned with our business's mission and objectives.`,
    //         `Seamlessly communicate complex technical concepts to both technical and non-technical stakeholders.`,
    //         `Embrace challenges as opportunities for growth, continuously expanding your skills and contributing to our collaborative environment.`,
    //     ],
    //     qualifications: [
    //         'Over 10 years of experience in diverse software development roles, with at least 3 years focused on software design and architecture.',
    //         'Proficiency in statically typed languages such as Go, Scala, C++, or Java, along with experience in containerized deployment.',
    //         'Proven track record of testing, launching, and optimizing software products.',
    //         'Excellent analytical and problem-solving skills, coupled with the ability to take ownership of large-scale projects.',
    //         'BS in Computer Science, a related field, or equivalent practical experience.',
    //     ],
    //     compensation: `£120K`,
    // },

    // {
    //     id: 'frontend-developer',
    //     title: 'Frontend Developer',
    //     overview: 'We are seeking a talented Frontend Developer to join our team. In this role, you will collaborate with our design and backend teams to create user-friendly web applications. Your responsibilities will include developing intuitive interfaces, optimizing performance, and ensuring a seamless user experience. If you have a passion for creating visually appealing and responsive applications, we would love to hear from you.',
    //     responsibilities: [
    //         'Develop user-friendly web applications using modern frontend technologies.',
    //         'Collaborate with designers and backend developers to implement UI/UX designs.',
    //         'Optimize applications for maximum speed and scalability.',
    //     ],
    //     qualifications: [
    //         'Strong proficiency in HTML, CSS, and JavaScript.',
    //         'Experience with React.js or other modern frontend frameworks.',
    //         'Familiarity with version control systems (e.g., Git).',
    //     ],
    //     compensation: 'Competitive salary based on experience.',
    // },

    // {
    //     id: 'backend-developer',
    //     title: 'Backend Developer',
    //     overview: 'We are looking for an experienced Backend Developer to build and maintain robust backend systems. As a Backend Developer, you will design and implement scalable APIs, collaborate with frontend developers to integrate features, and ensure the reliability and security of our systems. If you thrive in a fast-paced environment and have a strong background in server-side programming, we invite you to join our team and contribute to the success of our software solutions.',
    //     responsibilities: [
    //         'Design and implement scalable backend systems and APIs.',
    //         'Collaborate with frontend developers to integrate user-facing elements.',
    //         'Monitor and maintain system performance, security, and reliability.',
    //     ],
    //     qualifications: [
    //         'Proficiency in server-side languages such as Node.js, Python, or Ruby.',
    //         'Experience with database systems like MySQL, MongoDB, or PostgreSQL.',
    //         'Knowledge of RESTful API design principles.',
    //     ],
    //     compensation: 'Competitive salary and benefits package.',
    //   },

    // {
    //     id: `kunior-software-engineer`,
    //     title: `Junior Software Engineer`,
    //     overview: `As a Junior Software Engineer ... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    //     responsibilities: [
    //         'Design and implement scalable backend systems and APIs.',
    //         'Collaborate with frontend developers to integrate user-facing elements.',
    //         'Monitor and maintain system performance, security, and reliability.',
    //     ],
    //     qualifications: [
    //         'Proficiency in server-side languages such as Node.js, Python, or Ruby.',
    //         'Experience with database systems like MySQL, MongoDB, or PostgreSQL.',
    //         'Knowledge of RESTful API design principles.',
    //     ],
    //     compensation: `£10`,
    // },

    // {
    //     id: `project-manager`,
    //     title: `Project Manager`,
    //     overview: `As a project manager ... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    //     responsibilities: [
    //         'Design and implement scalable backend systems and APIs.',
    //         'Collaborate with frontend developers to integrate user-facing elements.',
    //         'Monitor and maintain system performance, security, and reliability.',
    //     ],
    //     qualifications: [
    //         'Proficiency in server-side languages such as Node.js, Python, or Ruby.',
    //         'Experience with database systems like MySQL, MongoDB, or PostgreSQL.',
    //         'Knowledge of RESTful API design principles.',
    //     ],
    //     compensation: `£90K`,
    // },

    // {
    //     id: 'UI-UX-designer',
    //     title: 'UI/UX Designer',
    //     overview: 'Join our design team as a UI/UX Designer to shape the user experience and visual design of our cutting-edge maritime software solutions. Collaborate with cross-functional teams to create intuitive and visually appealing interfaces that provide seamless interaction for our users. Your work will play a crucial role in enhancing user engagement and satisfaction.',
    //     responsibilities: [
    //         'Design and iterate on user interfaces for our software applications, ensuring a smooth and intuitive user experience.',
    //         'Collaborate with product managers, developers, and other stakeholders to gather requirements and translate them into visually appealing design concepts.',
    //         'Create wireframes, mockups, and prototypes to communicate design ideas and user flows.',
    //         'Conduct user research and usability testing to gather insights and validate design decisions.',
    //         'Stay up-to-date with design trends, best practices, and emerging technologies to drive continuous improvement in our products.',
    //     ],
    //     qualifications: [
    //         'Proven experience as a UI/UX Designer, with a strong portfolio showcasing user-centered design solutions.',
    //         'Proficiency in design and prototyping tools such as Adobe XD, Figma, Sketch, or similar.',
    //         'Solid understanding of user-centered design principles, usability, and accessibility.',
    //         'Excellent communication skills and the ability to present and explain design concepts.',
    //         'Degree in Interaction Design, Human-Computer Interaction, Graphic Design, or related field.',
    //     ],
    //     compensation: '£80K - £90K',
    // },

    // {
    //     id: 'data-scientist',
    //     title: 'Data Scientist',
    //     overview: 'As a Data Scientist at Marine AI, you will be at the forefront of turning maritime data into actionable insights. Collaborate with cross-functional teams to design and implement machine learning models that drive business decision-making and enhance the performance of our maritime solutions. Your work will have a significant impact on optimizing operations and increasing efficiency.',
    //     responsibilities: [
    //         'Collect, clean, and analyze maritime data to identify trends, patterns, and opportunities for optimization.',
    //         'Design and develop machine learning models for predictive analytics and anomaly detection.',
    //         'Collaborate with software engineers and domain experts to deploy models in production environments.',
    //         'Conduct experiments and A/B testing to validate the effectiveness of models and algorithms.',
    //         'Communicate findings and insights to technical and non-technical stakeholders through data visualization and storytelling.',
    //     ],
    //     qualifications: [
    //         'Proven experience as a Data Scientist, with a track record of delivering data-driven solutions.',
    //         'Strong proficiency in programming languages such as Python and libraries like TensorFlow, PyTorch, or scikit-learn.',
    //         'Experience with data preprocessing, feature engineering, and model selection.',
    //         'Familiarity with data visualization tools such as Matplotlib, Seaborn, or Tableau.',
    //         'Master’s or PhD in Computer Science, Data Science, Statistics, or a related field.',
    //     ],
    //     compensation: '£90K - £100K',
    // },
    
    // {
    //     id: 'product-manager',
    //     title: 'Product Manager',
    //     overview: 'Join our product team as a Product Manager to drive the strategy and development of innovative maritime software solutions. Collaborate with cross-functional teams to define product roadmaps, prioritize features, and deliver products that meet the needs of our maritime clients. Your strategic thinking and leadership will play a pivotal role in shaping the future of our products.',
    //     responsibilities: [
    //         'Define and prioritize product features based on market research, customer feedback, and business goals.',
    //         'Work closely with engineering, design, and marketing teams to develop and launch high-quality software products.',
    //         'Create detailed product requirements, user stories, and acceptance criteria for development teams.',
    //         'Monitor product performance, gather feedback, and continuously iterate to improve user experience.',
    //         'Stay informed about industry trends and competitive landscape to drive product innovation.',
    //     ],
    //     qualifications: [
    //         'Proven experience as a Product Manager, with a track record of delivering successful software products.',
    //         'Strong understanding of Agile methodologies and product development processes.',
    //         'Excellent communication and leadership skills, with the ability to collaborate with cross-functional teams.',
    //         'Analytical mindset with the ability to make data-driven decisions and prioritize effectively.',
    //         'Bachelor’s or Master’s degree in Business, Computer Science, Engineering, or related field.',
    //     ],
    //     compensation: '£100K - £110K',
    // },

    // {
    //     id: 'dev-ops-engineer',
    //     title: 'DevOps Engineer',
    //     overview: 'As a DevOps Engineer, you will play a critical role in ensuring the reliability, scalability, and performance of our maritime software solutions. Collaborate with development and operations teams to automate deployment, monitoring, and infrastructure management processes. Your expertise will contribute to the continuous improvement of our CI/CD pipelines and overall system architecture.',
    //     responsibilities: [
    //         'Design, implement, and manage automated CI/CD pipelines for software deployment.',
    //         'Configure and manage cloud infrastructure using tools like AWS, Azure, or Google Cloud.',
    //         'Monitor system performance, identify bottlenecks, and implement performance optimization strategies.',
    //         'Collaborate with development teams to troubleshoot and resolve production issues.',
    //         'Stay updated with industry best practices and emerging technologies in DevOps and cloud computing.',
    //     ],
    //     qualifications: [
    //         'Proven experience as a DevOps Engineer, with hands-on experience in CI/CD pipelines and cloud platforms.',
    //         'Proficiency in infrastructure-as-code tools such as Terraform or CloudFormation.',
    //         'Experience with containerization technologies like Docker and orchestration tools like Kubernetes.',
    //         'Strong scripting skills in languages like Bash, Python, or PowerShell.',
    //         'Bachelor’s or Master’s degree in Computer Science, Engineering, or a related field.',
    //     ],
    //     compensation: '£95K - £105K',
    // },
    
    // {
    //     id: 'technical-writer',
    //     title: 'Technical Writer',
    //     overview: 'Join our technical documentation team as a Technical Writer to create clear, concise, and comprehensive documentation for our maritime software solutions. Collaborate with subject matter experts to gather information and produce user manuals, API documentation, and technical guides. Your role will contribute to enhancing the usability and adoption of our products.',
    //     responsibilities: [
    //         'Create and maintain technical documentation for software products, including user guides and API references.',
    //         'Collaborate with engineers and product managers to understand complex technical concepts and features.',
    //         'Gather information from subject matter experts and conduct research to ensure accuracy of documentation.',
    //         'Organize and structure documentation content for easy navigation and user-friendly experience.',
    //         'Stay informed about product updates and changes to update documentation accordingly.',
    //     ],
    //     qualifications: [
    //         'Proven experience as a Technical Writer, with a portfolio of technical documentation projects.',
    //         'Excellent writing and communication skills, with the ability to explain complex topics in a clear and concise manner.',
    //         'Familiarity with documentation tools like Markdown, Git, and version control systems.',
    //         'Strong attention to detail and ability to work collaboratively with cross-functional teams.',
    //         'Bachelor’s or Master’s degree in Technical Communication, Computer Science, or related field.',
    //     ],
    //     compensation: '£75K - £85K',
    // }
];

export default JobPostData;
