export const docTree = [
    {
        title: 'Products',
        link: '/products',
        sections: [
            {
                sectionTitle: 'Guardian AI',
                id: 'guardian-ai'
            },
            {
                sectionTitle: 'Guardian AI Autonomy',
                id: 'guardian-ai-autonomy'
            },
            {
                sectionTitle: 'Guardian AI Vision',
                id: 'guardian-ai-vision'
            },
            {
                sectionTitle: 'Guardian AI Helm',
                id: 'guardian-ai-helm'
            },
            {
                sectionTitle: 'Guardian AI Port',
                id: 'guardian-ai-port'
            },
            {
                sectionTitle: 'Guardian AI Integration',
                id: 'guardian-ai-integration'
            },
        ]
    },
    {
        title: 'Company',
        link: '/company',
        sections: [
            {
                sectionTitle: 'Our Mission',
                id: 'our-mission'
            },
            {
                sectionTitle: 'About Marine AI',
                id: 'about-marine-ai'
            },
            {
                sectionTitle: 'Our Team',
                id: 'our-team'
            },
            {
                sectionTitle: 'Marine AI Blog',
                id: 'marine-ai-blog'
            },
        ]
    },
    {
        title: 'Career',
        link: '/careers',
        sections: [
            {
                sectionTitle: 'Our Values',
                id: 'our-values'
            },
            {
                sectionTitle: 'Opportunities at MAI',
                id: 'job-openings'
            },
        ]
    },
    {
        title: 'Contact',
        link: '/contact',
        sections: [
            {
                sectionTitle: 'Contact Us',
                id: 'contact-us'
            },
            {
                sectionTitle: 'Find Us',
                id: 'find-us'
            },
        ]
    },
    {
        title: 'FAQs',
        link: '/FAQs',
        sections: []
    },
]