import React, { Suspense, lazy } from 'react';

//css
import './contactPage.css'

//components
import MainPageTemplate from '../pageTemplate/MainPageTemplate.jsx';
import LoadingPage from '../pageTemplate/LoadingPage.jsx';

const ContactPageContactUs = lazy(() => import('./ContactPageContactUs.jsx'));
const ContactPageFindUs = lazy(() => import('./ContactPageFindUs.jsx'));

export default function ContactPage() {
	return (
		<MainPageTemplate title={'Contact Us | Marine AI'} showChatBot={true}>
			<Suspense fallback={<LoadingPage />}>
				<div className='contact-page-background'>
					<ContactPageContactUs />
					<ContactPageFindUs />
				</div>
			</Suspense>
		</MainPageTemplate>
	);
};