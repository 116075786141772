import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

//hooks
import useStore from './hooks/useStore.jsx';

//Static Pages
import Homepage from './components/homepage/Homepage.jsx';
import ProductPage from './components/productPage/ProductPage.jsx';
import CompanyPage from './components/companyPage/CompanyPage.jsx';
import CareerPage from './components/careerPage/CareerPage.jsx';
import ContactPage from './components/contactPage/ContactPage.jsx';
import FaqPage from './components/FaqPage/FrequentlyAskedQuestionsPage.jsx';
import NotFound from './components/pageTemplate/NotFound.jsx';

//Dynamic Pages
import BlogPostPage from './components/blog/BlogPostPage.jsx';
import JobPostPage from './components/careerPage/JobPostPage.jsx';

//Data
import BlogPosts from './data/blog-posts.js';
import JobPosts from './data/job-posts.js';

//Styles
import './styles/App.css';
import './styles/componentStyles.css';

export default function App() {
	const updateSize = useStore(state => state.updateSize);

	useEffect(() => {
		const handleResize = () => {
			updateSize();
		};

		window.addEventListener('resize', handleResize);

		updateSize();

		return () => window.removeEventListener('resize', handleResize);
	}, [updateSize]);

	return (
		<Routes>
			{/*Static Pages*/}
			
			<Route path="/" element={<Homepage posts={BlogPosts} />} />
			<Route path="/products" element={<ProductPage />} />
			<Route path="/company" element={<CompanyPage posts={BlogPosts} />} />
			<Route path="/careers" element={<CareerPage JobPostData={JobPosts} />} />
			<Route path="/contact" element={<ContactPage />} />
			<Route path="/FAQs" element={<FaqPage />} />

			{/*Dynamic Pages*/}
			<Route path="/blog-post/:postId" element={<BlogPostPage BlogData={BlogPosts} />} />
			<Route path="/job-opportunity/:jobId" element={<JobPostPage JobData={JobPosts} />} />

			{/*404 Not Found*/}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}
