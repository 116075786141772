import React from "react";

//components
import MainPageTemplate from "./MainPageTemplate.jsx";

//hooks
import PageLink from "../../hooks/PageLink.jsx";
import useStore from "../../hooks/useStore.jsx";

export default function NotFound() {
    const { isMobile } = useStore(state => ({
        isMobile: state.isMobile
    }));

    //links
    const links = [
        { name: 'home', path: '/' },
        { name: 'Products', path: '/products' },
        { name: 'Company', path: '/company' },
        { name: 'Careers', path: '/careers' },
        { name: 'Contact', path: '/contact' },
    ]

    return (
        <MainPageTemplate title={'404 | Page Not Found'} showChatBot={false}>
            <div className="not-found-container">
                <div className="not-found-content-wrapper">
                    <div className="not-found-left">
                        <h3 className="not-found-404">
                            404
                        </h3>
                    </div>

                    <div className="not-found-right">
                        <div className="not-found-text">
                            <p>This page could not be found.</p>

                            {links.map(link => ( //links to other site pages
                                <ul key={link.name} style={{margin: 0}}>
                                    <PageLink style={{ display: 'flex', justifyContent: 'space-between' }} path={link.path} id=''>
                                        <li className="not-found-link">
                                            {link.name}
                                            <span className='not-found-link-arrow'>
                                                ❯
                                            </span>
                                        </li>
                                    </PageLink>
                                </ul>
                            ))}
                            
                            {isMobile && (<br />)}
                        </div>
                    </div>
                </div>
            </div>
        </MainPageTemplate>
    )
}